import React, { FC } from 'react';
import { Button } from 'antd';
import DemoApp from './DemoApp';
import './App.css';

const App: FC = () => (
    <div className="App">
        <DemoApp/>
    </div>
);

export default App;