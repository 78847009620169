import './_visual-elements.scss'


type BadgeProps = {
    color?: string
}

const Badge: React.FC<BadgeProps> = ({color, children}) => {
    return (
        <div className={"badge " +color}>
            {children}
        </div>
    )
}

export default Badge