import React, { useEffect, useState } from 'react'
import { Card, message, Spin } from 'antd';
import { FileUploader } from '../RareCareVisualElements';
import axios from 'axios';

type Props = {
    //title: string
    // case_id: string
}

const AttestationDemo: React.FC<Props> = (props) => {
    const [defaultFileList, setDefaultFileList] = useState([]);
    const [filename, setFilename] = useState("")
    const [results, setResults] = useState("")
    const [progress, setProgress] = useState(0);
    const [jobIdTextract, setJobIdTextract] = useState<string>('');
    const [textractCompleted, setTextractCompleted] = useState<boolean>(false);


    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    const checkJobStatus = () => {
        if (jobIdTextract === '') {
            return;
        }

        console.log("Checking Job Status");

        const data = {
            Job_id: jobIdTextract,
            FileName: filename,
            Action: 'STATUS'
        }

        axios.post('https://demo-api.tma.healthcare/processAttestation', data)
            .then(res => {
                console.log(res.data);
                console.log(res.data.Body.Status);
                console.log(textractCompleted);
                if (res.data.Error) {
                    message.error(res.data.Body)
                    return
                }
                else if(res.data.Body.Status === 'SUCCEEDED'){
                    message.success('Job Completed');
                    setTextractCompleted(true)
                    getTextractResult();
                    console.log(textractCompleted);
                    return
                }
                else if(res.data.Body.Status === 'FAILED'){
                    message.error('Job Failed');
                    setTextractCompleted(true)
                    return
                }
                else{
                    message.info('Text Extraction Job Still Running');
                    setTimeout(() => checkJobStatus(), 5000);
                }
            })
            .catch(err => {
                console.log(err);
            })

        console.log(textractCompleted);

    }

    const getTextractResult = () => {
        const data = {
            Job_id: jobIdTextract,
            FileName: filename,
            Action: 'RESULTS'
        }
        axios.post('https://demo-api.tma.healthcare/processAttestation', data)
            .then(res => {
                console.log(res.data)
                setResults(res.data.Body.Results)
            })
    }


    useEffect(() => {
        checkJobStatus();
    }, [jobIdTextract])


    useEffect(() => {
        getTextractResult();
        }, [textractCompleted]
    )

    const uploadPDF = async options => {
        console.log("=================")
        console.log(options)
        const { onSuccess, onError, file, onProgress } = options;

        //const fmData = new FormData();

        const pdf_blob = await getBase64(file) as string;
        const payload_data = {
            FileName: defaultFileList[0].name,
            PDF: pdf_blob.replace(/^data:application\/pdf;base64,/, "")
        }

        console.log(payload_data.FileName);
        const config = {
            headers: { "content-type": "multipart/form-data"},
            onUploadProgress: event => {
                const percent = Math.floor((event.loaded / event.total) * 100);
                setProgress(percent);
                if (percent === 100) {
                    setTimeout(() => setProgress(0), 1000);
                }
                onProgress({ percent: (event.loaded / event.total) * 100 });
            }
        };
        //fmData.append("pdf", file);
        try {
            const res = await axios.post(
                'https://demo-api.tma.healthcare/uploadPDF',
                payload_data,
                config
            );


            if (res.data.Error) {
                message.error(res.data.Body);
                onError("Error");
                return
            }
            message.success("PDF successfully uploaded")
            onSuccess("Ok");
            await handleProcessPDF()

        } catch (err) {
            console.log("Error: ", err);
            //const error = new Error("Some error");
            const error = "Error uploading PDF" + err.toString()
            message.error(error)
            onError({ err });
        }
    };

    const handleProcessPDF = async () => {
        console.log("Processing PDF")
        const payload_data = {
            FileName: filename,
            Action: 'START_JOB'
        }
        const res = await axios.post(
            'https://demo-api.tma.healthcare/processAttestation',
            payload_data,
        );
        console.log(res);
        setJobIdTextract(res.data.Body.Job_id);

    }

    const handleOnChange = ({ file, fileList, event }) => {
        // console.log(file, fileList, event);
        //Using Hooks to update the state to the current filelist
        setDefaultFileList(fileList);
        setFilename(fileList[0].name)
        //filelist - [{uid: "-1",url:'Some url to image'}]
    };

    return(
        <>
            <Card title="Select file to process">
                <FileUploader
                    url={'https://demo-api.tma.healthcare/uploadPDF'}
                    customRequest={uploadPDF}
                    onChange={handleOnChange}
                    buttonText="Select File"
                    accept=".pdf"
                />
            </Card>
            <Card title={"Form Values Extraction"}>
                {(jobIdTextract !== '') && (!textractCompleted) && <Spin/>}

                {(results !== '') && <div dangerouslySetInnerHTML={{ __html: results }}></div> }
            </Card>
            <Card title={"PDF Text Identification"}>

            </Card>
        </>
    )

}

export default AttestationDemo;