import React, { useState } from 'react';
import { Upload, Button, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';


type Props = {
    url: string;
    customRequest: any;
    buttonText: string;
    accept: string;
    onChange: any;
}


const FileUploader: React.FC<Props> = (props) => {
  const upload_props = {
    // beforeUpload: file => {
    //   if (file.type !== 'image/png') {
    //     message.error(`${file.name} is not a png file`);
    //   }
    //   return file.type === 'image/png' ? true : Upload.LIST_IGNORE;
    // },
    action: props.url,
    customRequest: props.customRequest,
    accept: props.accept,
    onChange: props.onChange,
  };
  return (
    <Upload {...upload_props}>
      <Button icon={<UploadOutlined />}>{props.buttonText}</Button>
    </Upload>
  );
};

export default FileUploader;