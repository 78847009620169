import React, { useState } from 'react'
// import { RARECARE_API } from '../../config'
import { Button, Table, Collapse, Row, message } from 'antd'
// import { addMalaCardToCase } from '../../RareCareAPI';
import { Badge } from '../RareCareVisualElements'
// import { refreshLifeMapAnalysisCard } from '../LifeMap/LifeMapAnalysisCard';


const { Panel } = Collapse;

type RareCareDiseaseMatrixProps = {
    diagnostics?: Array<string>
    analysis: Array<any>
    // case_id: string
    // lifemap_analysis_card: any /* The instance of the Lifemap analysis card to update */
}

type RareCareDiseaseMatrixState = {
    diseases: Array<any>
    error: any
    isLoaded: boolean
    isAddClicked: boolean
}

//TODO: import this components from LifeMap Card
class RareCareDiseaseMatrix extends React.Component<RareCareDiseaseMatrixProps, RareCareDiseaseMatrixState> {
    constructor(props: RareCareDiseaseMatrixProps) {
        super(props)
        this.state = {
            diseases: [],
            error: null,
            isLoaded: false,
            isAddClicked: false
        }
    }

    setIsAddClicked(val: boolean) {
        this.setState({
            isAddClicked: val
        })
    }

    // componentDidMount() {
    //     const requestOptions = {
    //         method: 'POST',
    //         headers: { 'Content-Type': 'application/json' },
    //         body: JSON.stringify({
    //             'diagnostics_list' : this.props.diagnostics
    //         })
    //     }
    //     fetch(RARECARE_API + "tma_automatic_case_analysis", requestOptions)
    //     .then(response => response.json())
    //     .then( res =>
    //         {
    //             this.setState({
    //                 diseases: res,
    //                 isLoaded: true
    //             })
    //     });
    // }
    //
    // handleAddMalacard = async (McId: any) => {
    //     this.setIsAddClicked(true) ;
    //     const res:any = await addMalaCardToCase(McId, this.props.case_id );
    //     if (res.status === 200) {
    //         if (!res.data.Error) {
    //             message.success("Successfully added Malacard to case")
    //             refreshLifeMapAnalysisCard(this.props.lifemap_analysis_card)
    //         }
    //         else {
    //             message.error("Failed to add Malacard to case: "+res.data.Body);
    //         }
    //     } else {
    //         message.error("Failed to add Malacard to case: Error connecting to backend");
    //     }
    //
    // }

    render() {

        const columns = [
            {
              title: 'MalaCards ID',
              dataIndex: 'McId',
              sorter: (a, b) => a.malacard_id.localeCompare(b.malacard_id),
              multiple: 2
            },
            {
              title: 'Disease Name',
              dataIndex: 'DiseaseName',
              sorter: (a, b) => a.disease_name.localeCompare(b.disease_name),
              multiple: 2
            },
            {
              title: 'Summaries',
              dataIndex: 'Summaries',
              // sorter: (a, b) => a.number.localeCompare(b.number),
              multiple: 2,
              render: (data, item, index) => (
                <Collapse 
                //expandIcon={false} 
                key={index}>
                  <Panel header="Description" key={index}>
                    <ul>
                      {data.map((summary, index) => <li key={index}><b>{summary['Source']} </b>{summary['Summary']}</li>)}
                    </ul>
                  </Panel>
                </Collapse>
              )
            },
            {
                title: 'Matching Diagnostics',
                dataIndex: 'matching_diagnostics',
                multiple: 2,
                render: (data, item, index) => (
                   <Row>
                   {item.matching_diagnostics.map(diag => <Badge color="green">{diag}</Badge>)}
                   </Row>
                ),
                sorter: (a, b) => b.matching_diagnostics.length - a.matching_diagnostics.length,
                sortDirection: ['descend'],
               },            
            {
              title: 'Action',
              dataIndex: 'Action',
              render: (data, item, index) => { return(
                <Button 
                    key={index} 
                    onClick={() => {
                        // this.handleAddMalacard(item.McId)
                    }}
                >
                    Add
                </Button>
              )}
            },
        ]
          
        // if(this.state.isLoaded) {
            return (
                <>
                    < div className="tma-table">
                    <div className="strip-table">
                        <Table
                        columns={columns}
                        dataSource={this.props.analysis}
                        pagination={false}
                        // loading={!this.state.isLoaded}
                        //locale={!showtableSpinner ? getLocale(true) : getLocale(false)}
                        />
                    </div>
                    </div>
                    
                </>
            )
        // } else {
        //     return(<>
        //     ...Loading
        //     </>
        //     )
        // }
    }
}

export default RareCareDiseaseMatrix;
