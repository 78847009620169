import React, { useEffect, useState } from 'react'
import { Chart } from "react-google-charts";
import { GridContent } from '@ant-design/pro-layout';
import {Row, Col, Badge, Card, message, Spin, Table, Timeline, Tabs} from 'antd';
import { FileUploader } from '../RareCareVisualElements';
import axios from 'axios';
import { ConsoleSqlOutlined } from '@ant-design/icons';
import RareCareDiseaseMatrix from "./RareCareDiseaseMatrix";
// import demos from "./demos.less";
const { TabPane } = Tabs;

// const API_ENDPOINT="http://localhost:5000"
const API_ENDPOINT="https://journey-api.tma.healthcare"


type PropsTable = {
    meds: any
}



const MedicationTimeline: React.FC<PropsTable> = (props) => {

    console.log(props.meds)
    const columns = [
        { type: "string", id: "Medication" },
        { type: "date", id: "Date" },
        { type: "date", id: "End" },
    ];


    const rows = props.meds.map((med: any) => {
        return [
            med.Medication,
            Date.parse(med.Date),
            Date.now()
        ];
    });

    console.log(rows)
    // const rows = [
    //     ["Washington", new Date(1789, 3, 30), new Date(1797, 2, 4)],
    //     ["Adams", new Date(1797, 2, 4), new Date(1801, 2, 4)],
    //     ["Jefferson", new Date(1801, 2, 4), new Date(1809, 2, 4)],
    // ];

    const data = [columns, ...rows]
    return <Chart chartType="Timeline" data={data} width="100%" height="800px" />;

}

const MedicationTable: React.FC<PropsTable> = (props) => {


    const processMeds = (meds) => {
        let medsList = []
        for (let med in meds) {
            let tableMed = meds[med]
            tableMed.name = med
            medsList.push(tableMed)
        }
        return medsList
    }

    const columns = [
        {
            title: 'Medication',
            dataIndex: 'Medication',
        },
        {
            title: 'Dosage',
            dataIndex: 'Dosage',
        },
        {
            title: 'Frequency',
            dataIndex: 'Frequency'
        },
        {
            title: 'Route',
            dataIndex: 'route',
        },
        {
            title: 'Strength',
            dataIndex: 'Strength',
        },
        {
            title: 'Form',
            dataIndex: 'Form',
        },
        // {
        //     title: 'Start Date',
        //     dataIndex: 'Start_date',
        // },
        // {
        //     title: 'End Date',
        //     dataIndex: 'End_date',
        // },
    ];

    return (
        <Table dataSource={processMeds(props.meds)} columns={columns} />
    )
}

type PropsTimeline = {
    itemsList: any
}

const DEMOTimeline: React.FC<PropsTimeline> = (props) => {

    const itemsList = props.itemsList




    const timelineItemGrid = (items, title, color) => {
        let timelineGridItem = []
        for (let i in items){
            console.log(items)
            console.log(typeof items[i])
            let desc = items[i]
            if (typeof items[i] === 'object'){
                desc = items[i].Description
            }
            timelineGridItem.push(
                <Card.Grid style={{
                    width: '25%',
                    backgroundColor: color,
                    color: '#0f0',
                }}>
                    <p style={{ color: '#efefef' }}><b>{i}</b></p>
                    <p style={{ color: '#efefef' }}>{desc}</p>
                </Card.Grid>)
            // </Timeline.Item>
        }
        return (<Timeline.Item color={color} position={"left"}>
            <Card
                title={title}
                headStyle={{backgroundColor: color, color: '000'}}
            >
                {timelineGridItem}
            </Card>
        </Timeline.Item>)
    }


    const processTimeline = (itemsList) => {
        let res = []
        for (let date in itemsList) {

            res.push(<Timeline.Item
                color={"purple"}
                position={"right"}
            >
                <div
                    style={{
                        backgroundColor: 'purple',
                        color: '#fff',
                        borderRadius: '10px',
                        paddingRight: '15px',
                        fontSize: '15px',
                    }}
                >
                    {date}
                </div>
            </Timeline.Item>)

            const symp = itemsList[date]['Symptoms']
            if (Object.keys(symp).length > 0) {
                res.push(timelineItemGrid(symp, 'Symptoms', '#fdd246'))
            }
            // for (let s in symp) {
            //     res.push(<Timeline.Item color={"red"}>
            //        <Card
            //            title={'Symptoms: ' + s}
            //            headStyle={{backgroundColor: '#f5f5f5', color: '#000'}}
            //        >
            //            {symp[s]}
            //        </Card>
            //     </Timeline.Item>)
            // }

            const diag = itemsList[date]['Diagnostics']
            if (Object.keys(diag).length > 0) {
                res.push(timelineItemGrid(diag, 'Diagnostics', '#fe2360'))
            }
            // for (let d in diag){
            //     console.log(diag)
            //     res.push(<Timeline.Item color={"green"}>
            //         <Card
            //             title={'Diagnostics: ' + d}
            //             headStyle={{backgroundColor: '#00b5ad', color: '#fff'}}
            //         >
            //             {diag[d]}
            //         </Card>
            //     </Timeline.Item>)
            // }


            const proc = itemsList[date]['Procedures']
            if (Object.keys(proc).length > 0) {
                res.push(timelineItemGrid(proc, 'Procedures', '#971781'))
            }
            // let procTimelineItem = []
            // for (let p in proc){
            //     procTimelineItem.push(
            //         <Card.Grid style={{
            //             width: '25%',
            //             backgroundColor: '#f0ad4e',
            //             color: '#fff'
            //         }}>
            //             <p><b>{p}</b></p>
            //             <p>{proc[p]}</p>
            //         </Card.Grid>)
            // }
            // res.push(<Timeline.Item color={"orange"}>
            //     <Card
            //         title={"Procedures"}
            //         headStyle={{backgroundColor: '#f0ad4e', color: '#fff'}}
            //     >
            //         {procTimelineItem}
            //     </Card>
            // </Timeline.Item>)

            // let medTimeline = []
            // itemsList[date]["Medications"].map(med => {
            //     // res.push(<Timeline.Item color={"black"}><p><b>{med["Medication"]}</b></p>{med["Medication_description"]}</Timeline.Item>)
            //     medTimeline.push(
            //         <Card.Grid style={{
            //             width: '25%',
            //             backgroundColor: '#333',
            //             color: '#fff'
            //         }}>
            //             <p style={{ color: '#efefef' }}><b>{med["Medication"]}</b></p>
            //             <p style={{ color: '#efefef' }}>{med["Medication_description"]}</p>
            //         </Card.Grid>
            //     )
            // })
            //
            // if (medTimeline.length > 0) {
            //     res.push(<Timeline.Item color={"black"} position={"left"}>
            //         <Card
            //             title={"Medications"}
            //             headStyle={{backgroundColor: '#333', color: '#fff'}}
            //         >
            //             {medTimeline}
            //         </Card>
            //     </Timeline.Item>)
            // }

            const med = itemsList[date]['Medications']
            if (Object.keys(med).length > 0) {
                console.log(med)
                res.push(timelineItemGrid(med, 'Medications', '#20226d'))
            }

        }

        return res
    }

    return (
        <Card
            title={"Patient Journey"}
            style={{
                textAlign: 'left',
                width: '100%',
                backgroundColor: '#fff',
                color: '#000'
            }}
        >
            <Timeline
                mode={"alternate"}
            >
                {processTimeline(itemsList) }
            </Timeline>
        </Card>
    )
}

type Props = {
    //title: string
    // case_id: string
}

const PDFImportDemo: React.FC<Props> = (props) => {
    const [defaultFileList, setDefaultFileList] = useState([]);
    const [filename, setFilename] = useState("")
    const [progress, setProgress] = useState(0);

    const [uploadCompleted, setUploadCompleted] = useState<boolean>(false);
    const [processPDFCompleted, setProcessPDFCompleted] = useState<boolean>(false);
    const [comprehensionCompleted, setComprehensionCompleted] = useState<boolean>(false);
    const [comprehension, setComprehension] = useState<any>();
    const [medications, setMedications] = useState<any>();
    const [medicationCompleted, setMedicationCompleted] = useState<boolean>(false);

    const [automaticAnalysisCompleted, setAutomaticAnalysisCompleted] = useState<boolean>(false);
    const [automaticAnalysis, setAutomaticAnalysis] = useState<any>();

    const [timeline, setTimeline] = useState([])



    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    const uploadPDF = async options => {
        const { onSuccess, onError, file, onProgress } = options;

        //const fmData = new FormData();

        const pdf_blob = await getBase64(file) as string;
        const payload_data = {
            FileName: defaultFileList[0].name,
            PDF: pdf_blob.replace(/^data:application\/pdf;base64,/, "")
        }

        // console.log(payload_data.FileName);
        const config = {
            headers: { "content-type": "multipart/form-data"},
            onUploadProgress: event => {
                const percent = Math.floor((event.loaded / event.total) * 100);
                setProgress(percent);
                if (percent === 100) {
                    setTimeout(() => setProgress(0), 1000);
                }
                onProgress({ percent: (event.loaded / event.total) * 100 });
            }
        };
        //fmData.append("pdf", file);
        try {
            const res = await axios.post(
                API_ENDPOINT + '/uploadPDF',
                payload_data,
                config
            );

            //setJobIdTextract(res.data.Body.JobId);
            //console.log(res.data.Body.JobId)
            if (res.data.Error) {
                message.error(res.data.Body);
                onError("Error");
                return
            }
            message.success("PDF successfully uploaded")
            onSuccess("Ok");
            setUploadCompleted(true)

        } catch (err) {
            console.log("Error: ", err);
            //const error = new Error("Some error");
            const error = "Error uploading PDF" + err.toString()
            message.error(error)
            onError({ err });
        }
    };

    useEffect(() => {
        if(uploadCompleted) {
            processPDF();
        }
    }, [uploadCompleted])


    const processPDF = () => {
        console.log("Process PDF");
        const data = {
            FileName: filename,
        }
        axios.post(API_ENDPOINT + '/processPDF', data)
            .then((res) => {
                // console.log(res.data);
                // setComprehension(res.data.Body)
                message.success('PDF Processing Completed');
                console.log("PDF Processing Completed");
                setProcessPDFCompleted(true)
            })
    }

    useEffect(() => {
        if(processPDFCompleted) {
            comprehendPDF();
        }
    }, [processPDFCompleted])


    const comprehendPDF = () => {
        console.log("Comprehending");
        const data = {
            FileName: filename,
        }
        axios.post(API_ENDPOINT + '/processComprehendResult', data)
            .then((res) => {
                // console.log(res.data);
                //setTimeline(res.data.Body.sort())
                //setComprehension(JSON.stringify(res.data.Body, null, 2))
                setComprehension(res.data)
                // console.log(comprehension)
                message.success('Comprehension Completed');
                // console.log("Comprehension Completed");
                // console.log(JSON.stringify(comprehension, null, 2))
                setComprehensionCompleted(true);
            })
    }

    useEffect(() => {
        if(comprehensionCompleted) {
            getMedications();
        }
    }, [comprehensionCompleted])

    const getMedications = () => {
        console.log("Getting Medications");
        const data = {
            FileName: filename,
        }
        axios.post(API_ENDPOINT + '/processRxResult', data)
            .then((res) => {

                console.log(res.data)
                setMedications(res.data)

                message.success('Medication Analysis Completed Successfully');
                console.log("Medication Analysis Completed");
                console.log(medications)

                // console.log(JSON.stringify(comprehension, null, 2))
                setMedicationCompleted(true);
            })
    }



    useEffect(() => {
        if(medicationCompleted) {
            analyzePDF();
        }
    }, [medicationCompleted])

    const analyzePDF = () => {
        console.log("Analyszing PDF");
        const data = {
            FileName: filename,
        }
        axios.post(API_ENDPOINT + '/automaticAnalysis', data)
            .then((res) => {
                console.log(res.data);
                //setTimeline(res.data.Body.sort())
                //setComprehension(JSON.stringify(res.data.Body, null, 2))
                setAutomaticAnalysis(res.data)
                console.log(automaticAnalysis)
                message.success('Automatic Analysis Completed');
                console.log("Automatic Analysis Completed");
                // console.log(JSON.stringify(comprehension, null, 2))
                setAutomaticAnalysisCompleted(true);
            })
    }


    const handleOnChange = ({ file, fileList, event }) => {
        // console.log(file, fileList, event);
        //Using Hooks to update the state to the current filelist
        setDefaultFileList(fileList);
        setFilename(fileList[0].name)
        //filelist - [{uid: "-1",url:'Some url to image'}]
    };

    return(
        <>
            {/*<DEMOTimeline itemsList={comprehension}/>*/}
            <Card title="Select file to process">
                <FileUploader
                    url={API_ENDPOINT + '/uploadPDF'}
                    customRequest={uploadPDF}
                    onChange={handleOnChange}
                    buttonText="Select File"
                    accept=".pdf"
                />
            </Card>
            <Card title="Processing Medical Record">
                {/*<Button type="primary" onClick={handleProcessPDF} className="btn primary-btn blue-btn">Process PDF</Button>*/}
                {uploadCompleted &&
                    <><h3>Processing PDF</h3>
                        { processPDFCompleted && <h4>Text Extraction and translation Completed <Badge color="green"/></h4>}
                    </>}


                {processPDFCompleted &&
                    <>
                        <h3>Processing Comprehension</h3>
                        {comprehensionCompleted && <h4>Comprehension Completed <Badge color="green"/></h4>}
                    </>
                }


            </Card>
            {comprehensionCompleted &&
              <Card>

              <Tabs defaultActiveKey={"TimeLine"}>



                  <TabPane tab={"Patient History"} key={"TimeLine"}>
                    <DEMOTimeline itemsList={comprehension}/>
                  </TabPane>
                  <TabPane tab={"Medications"} key={"Medications"}>

                    <Card
                      title={"Medications"}
                      style={{
                          textAlign: 'left',
                          width: '100%',
                          backgroundColor: '#fff',
                          color: '#000'
                      }}
                    >
                      <MedicationTimeline meds={medications}/>
                      <MedicationTable meds={medications}/>
                    </Card>
                  </TabPane>
                <TabPane tab={'Automatic Analysis'} key={"AutomaticAnalysis"}>
                  <Card
                    title={"Automatic Analysis"}
                    style={{
                        textAlign: 'left',
                        width: '100%',
                        backgroundColor: '#fff',
                        color: '#000'
                    }}
                  >
                    <RareCareDiseaseMatrix
                      analysis={automaticAnalysis}
                    />
                  </Card>
                </TabPane>

              </Tabs>
              </Card>
            }
        </>
    )

}

export default PDFImportDemo;
